import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import * as Styled from './styles';

const Divider = (props) => {
  const { site, placeholderImage, placeholderImage2 } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      placeholderImage: file(relativePath: { eq: "blog-segment.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage2: file(relativePath: { eq: "blog-segment-2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const logoTitle = site.siteMetadata.title;
  const logoImage = placeholderImage.childImageSharp.fluid;
  const logoImage2 = placeholderImage2.childImageSharp.fluid;

  return (
    <Styled.Divider>
      <Styled.Image>
        <Img fluid={props.page === "blog" ? logoImage   : logoImage2} alt={logoTitle} />
      </Styled.Image>
    </Styled.Divider>
  );
};

export default Divider;
