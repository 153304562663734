import React from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import Loadable from '@loadable/component';
import Img from 'gatsby-image';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Divider from 'components/ui/Divider';
import ContactCard from 'components/ui/ContactCard';
import FormatHtml from 'components/utils/FormatHtml';

import * as Styled from './styles';

const Carousel = Loadable(() => import('components/ui/CarouselImage'));

const PortfolioPost = ({ data, pageContext }) => {
  const portfolio = data.markdownRemark;
  const { previous, next } = pageContext;
  const screenshots = pageContext.data.screenshots;

  return (
    <Layout>
      <SEO title={portfolio.frontmatter.title} description={pageContext.data.description}/>
      <Styled.PortfolioPage section>
        <Styled.Titles>
          <h1>
            {portfolio.frontmatter.title}
          </h1>
          <time>
            {portfolio.frontmatter.date}
          </time>
        </Styled.Titles>
        <Divider page="portfolio"/>
        <FormatHtml content={portfolio.html} classContent="post-content" />
        <Carousel>
        {screenshots.map((image, i) => {
          return (
            <Img key={i} fluid={image.shot.childImageSharp.fluid} alt={""} />
        )})}
        </Carousel>
        <Styled.TagSection>
          <h3>Built with...</h3>
            <ul className="columns">
              {pageContext.data.tags.map((tag, i) => <Styled.Tag key={i}>{tag}</Styled.Tag>)}
            </ul>
        </Styled.TagSection>
        <ContactCard />
        <Styled.Links>
          <span className='prev'>
            {previous && (
              <Link to={previous.fields.slug} rel="previous">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </span>
          <span className='next'>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </span>
        </Styled.Links>
      </Styled.PortfolioPage>
    </Layout>
  );
};

export default PortfolioPost;

export const query = graphql`
  query PortfolioPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMM DD, YYYY")
      }
    }
  }
`;
