import styled from 'styled-components';

export const PortfolioPage = styled.section`
    padding-top: 12rem;
    margin: 0 auto;
    position: relative;

    p, h1, h2, h3, h4, h5, h6, hr {
        position: relative;
        z-index: 1;
        font-display: swap;
    }

    @media (max-width: 414px) {
        padding: 7rem 2rem 0;
    }

    &:before,
    &:after {
        display: block;
        content: '';
        clear: both;
    }

    h1 {
        margin: .67em 0;
        font-size: 3.2rem;
    }

    h2 {
        font-size: 3.2rem;
        font-family: 'Kosugi', sans-serif;
        padding-top: 4rem;
    }

    h4 {
        font-size: 2.5rem;
        font-family: 'Source', sans-serif;
        text-decoration: underline;
        padding-left: 2rem; 
    }

    hr {
        border: 3px solid #c3e0c3;
        border-radius: 6px;
        margin: 6rem 0;
    }

    .mt-100 {
        margin-top: 16rem;
    }

    .clear {
        clear: both;
    }

    .post-content {
        padding: 1em 0 4em 0;
        line-height: 1.6em;
        max-width: 63rem;
        margin: 0 auto;
        margin-top: 19rem;

        @media (max-width: 450px) {
            margin-top: 15rem;
        }

        @media (min-width: 450px) and (max-width: 800px) {
            margin-top: 18rem;
        }

        @media (min-width: 800px) {
            margin-top: 24rem;
        }

        .narrow {
            max-width: 32rem;
            margin: 0 auto !important;
        }

        em {
            font-style: italic;
        }

        ol {
            list-style-type: decimal;
        }

        code {
            font-family: monospace !important;
        }

        ul {
            margin-top: 1.6rem;
            li {
                line-height: 3rem;
                font-size: 2rem;
                list-style-type: disc;
                @media (max-width: 414px) {
                    line-height: 2.8rem;
                    font-size: 1.8rem;
                }
            }
        }

        ol {
            margin-top: 1.6rem;
            li {
                line-height: 3rem;
                font-size: 2rem;
                list-style-type: decimal;
                @media (max-width: 414px) {
                    line-height: 2.8rem;
                    font-size: 1.8rem;
                }
            }
        }

        h1, h2, h3, h4, h5, h6, p, li {
            font-family: 'Source', sans-serif;
        }

        h2, h3, h4, strong {
            font-family: 'Source-Bold'; 
        }

        h2 {
            margin: 2.4rem 0 1rem;
            font-size: 2.8rem;
            line-height: 1.4em;
        }

        h3 {
            margin: 2.4rem 0 1rem;
            font-size: 2.4rem;
            line-height: 1.4em;
        }

        h4 {
            margin: 2.4rem 0 1rem;
            font-size: 1.2rem;
        }

        p {
            margin: 1.6rem 0 0;
            line-height: 3rem;
            font-size: 2rem;
        }

        blockquote {
            margin: 2rem 0 2rem;
            padding: 0 0 0 2em;
            line-height: 1.2em;
            font-style: italic;
            font-size: 2.4rem;
        }

        pre {
            margin: 2rem 0 0;
        }

        a {
            color: green;
        }

        .button {
            padding: 1rem 2.5rem;
            background-color: #4CAF50;
            font-family: 'Source-Bold';     
            color: white;
            font-size: 1.7rem;
            border-radius: .7rem;
            transition: background-color 0.25s;
            &:hover {
                background-color: green;
            }
        }
    }
`;

export const Titles = styled.section`
    max-width: 90rem;
    margin: 0 auto; 
    text-align: center;
    position: relative;
    z-index: 2;
    
    h1 {
        font-family: 'Source-Bold'; 
        font-size: 6rem;
    }

    @media (max-width: 800px) {
        h1 {    
            font-size: 5rem;
            line-height: 5rem;
        }
    }

    time {
        font-family: 'Kosugi';
        font-size: 2rem;
    }
`;

export const Image = styled.figure`
`;

export const Tag = styled.li`
`;

export const TagSection = styled.section`
    padding: 1em 2em 4em 2em;
    margin-bottom: 10rem !important;
    line-height: 1.6em;
    max-width: 63rem;
    margin: 0 auto;

    @media (min-width: 414px) {
        .columns {
            column-count: 3;
        }
    }

    h1, h2, h3, h4, h5, h6, p, li {
        font-family: 'Source', sans-serif;
    }

    h2, h3, h4, strong {
        font-family: 'Source-Bold'; 
    }

    h3 {
        margin: 2.4rem 0 1rem;
        font-size: 2.8rem;
        line-height: 1.4em;
    }

    p, li {
        line-height: 3rem;
        font-size: 2rem;
    }
`;

export const Links = styled.div`
    max-width: 65rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6rem 0;
    font-family: 'Source', sans-serif;
    font-size: 2rem;

    .prev {
        text-align: left;
        width: 35%;

        @media (max-width: 414px) {
            width: 46%;
        }
    }

    .next {
        text-align: right;
        width: 35%;
        @media (max-width: 414px) {
            width: 46%;
        }
    }
`;

