import styled from 'styled-components';
import { FaLinkedin, FaTwitterSquare, FaGithubSquare } from 'react-icons/fa';

export const ContactCard = styled.div`
    max-width: 65rem;
    margin: 3rem 0 auto;
    display: flex;
    justify-content: left;
    align-items: center;

    @media (min-width: 414px) {
      margin: 0 auto;
    }
`;

export const Text = styled.div`
    padding-left: 2rem;

    h4 {
        font-family: 'Source-Bold';
        font-size: 2.5rem;
        text-decoration: none !important;
        padding: 0 !important;
        margin-bottom: .5rem;
        line-height: 2.7rem;
        color: #484b48;
        span {
          font-size: 2.5rem;
          color: rgb(153, 153, 153);

          @media (max-width: 414px) {
            font-family: 'Source-Light';
          }
        }
    }

    h5 {
        font-family: 'Source-Light';
        font-size: 2rem;
        margin-bottom: .5rem;
        line-height: 2.7rem;
        color: rgb(153, 153, 153);

        @media (max-width: 414px) {
            display: none;
        }
    }
`;

export const Image = styled.figure`
    width: 15rem;
`;

export const Icons = styled.div`
    display: flex;
`;

export const LI = styled(FaLinkedin)`
  font-size: 3rem;
  color: #4CAF50;
`;

export const TW = styled(FaTwitterSquare)`
  font-size: 3rem;
  color: #4CAF50;
`;

export const GH = styled(FaGithubSquare)`
  font-size: 3rem;
  color: #4CAF50;
`;