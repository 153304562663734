import styled from 'styled-components';

export const Divider = styled.div`
    position: absolute;
    top: -2rem;
    width: 100%;
    left: 0;

    @media (max-width: 550px) {
        top: 22rem;
    }

    @media (min-width: 550px) and (max-width: 700px) {
        top: 18rem;
    }

    @media (min-width: 700px) and (max-width: 900px) {
        top: 13rem;
    }

    @media (min-width: 900px) and (max-width: 1300px) {
        top: 5rem;
    }
`;

export const Text = styled.h1`
`;

export const Image = styled.figure`
`;
